import { useEffect, useState } from "react";
import apis from "../../services/apis";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import DoctorListTable from "./DoctorListTable";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const saveLocalData = (seachData, key) => {
  localStorage.setItem("previous_user_data", JSON.stringify(seachData));
  localStorage.setItem("previous_user_key", JSON.stringify(key));
};

const deleteLocalData = () => {
  localStorage.removeItem("previous_user_data");
  localStorage.removeItem("previous_user_key");
  localStorage.removeItem("previous_user_value");
};

const ExportExcel = (data) => {
  return (
    <ExcelFile
      element={
        <button
          style={{ width: "max-content" }}
          className="otjs-button otjs-button-green"
        >
          Export/Download
        </button>
      }
    >
      <ExcelSheet data={data.data} name="Employees">
        <ExcelColumn label="Patient Name" value="patient_name" />
        <ExcelColumn label="Patient ID" value="patient_id" />
        <ExcelColumn label="Accesion" value="accessor" />
        <ExcelColumn label="Study Type" value={"study_type"} />
        <ExcelColumn label="Study Date" value={"study_date"} />
        <ExcelColumn
          label="Status"
          value={(col) =>
            col.addendumby === undefined ? "Not Finalize" : "Finalize"
          }
        />
        <ExcelColumn
          label="Doctor Incharge"
          value={(col) => col.doctors.join(" , ")}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

const SearchForm = ({ setReports ,setRandom}) => {
  const [data, setData] = useState({});
  useEffect(() => {
    let data = localStorage.getItem("previous_user_data");
    if (data) {
      let key = localStorage.getItem("previous_user_key");
      try {
        if (key) {
          key = JSON.parse(key);
          setData(key);
          apis.caseList.searchDoctorCaseList(key).then((res) => {
            setReports(res);
          });
        }
      } catch {}
    }
  }, []);

  const handleChange = (e) => {
    setData((prevdata) => ({ ...prevdata, [e.target.name]: e.target.value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.study_date) {
      data.study_date = data.study_date.replaceAll("-", "");
    }
    apis.caseList
      .searchDoctorCaseList(data)
      .then((res) => {
        saveLocalData(res, data);
        setReports(res);
        setRandom(Math.random())
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="d-flex flex-wrap">
      <div className="row mt-4">
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="patient_name" className="form-label">
            Patient Name
          </label>
          <input
            type="text"
            name="patient_name"
            id="patient_name"
            value={data.patient_name}
            onChange={handleChange}
            className="form-control"
            placeholder="Patient Name"
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="patient_id" className="form-label">
            Patient ID
          </label>
          <input
            type="text"
            name="patient_id"
            id="patient_id"
            value={data.patient_id}
            onChange={handleChange}
            className="form-control"
            placeholder="Patient ID"
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="study_type" className="form-label">
            Study Type
          </label>
          <input
            type="text"
            name="study_type"
            id="study_type"
            value={data.study_type}
            onChange={handleChange}
            className="form-control"
            placeholder="Study Type"
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="study_date" className="form-label">
            Study Date
          </label>
          <input
            type="date"
            name="study_date"
            id="study_date"
            value={data.study_date}
            className="form-control"
            placeholder="Study Date"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="doctors" className="form-label">
            Keyword
          </label>
          <input
            type="text"
            name="keyword"
            id="keyword"
            className="form-control"
            placeholder="keyword"
            value={data.keyword}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="nric" className="form-label">
            NRIC
          </label>
          <input
            type="text"
            name="nric"
            value={data.nric}
            id="nric"
            className="form-control"
            placeholder="NRIC"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="RefPhysicianName" className="form-label">
            Referring Centre
          </label>
          <input
            type="text"
            name="RefPhysicianName"
            value={data.RefPhysicianName}
            id="RefPhysicianName"
            className="form-control"
            placeholder="Referring Centre"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="accesor" className="form-label">
            Accession Number
          </label>
          <input
            type="text"
            name="accesor"
            value={data.accesor}
            id="accesor"
            className="form-control"
            placeholder="Accession Number"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="" className="form-label">
           Type
          </label>
          <select
            type="text"
            name="type"
            value={data.type}
            id="accesor"
            className="form-select"
            onChange={handleChange}
          >
            <option hidden value=''></option>
            <option value='normal'>Normal</option>
            <option value='abnormal'>Abnormal</option>
            </select>
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="label" className="form-label">
            Label
          </label>
          <input
            type="text"
            name="label"
            value={data.label}
            id="label"
            className="form-control"
            placeholder="Label"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 mt-2">
          <label htmlFor="InstitutionName" className="form-label">
          Institution Name
          </label>
          <input
            type="text"
            name="InstitutionName"
            value={data.InstitutionName}
            id="InstitutionName"
            className="form-control"
            placeholder="Institution Name"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mt-4 justify-content-center d-flex">
          <button
            onClick={handleSubmit}
            className="btn otjs-button otjs-button-blue p-1"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

const DoctorCaseList = () => {
  const [reports, setReports] = useState([]);
  const [id, setID] = useState(null);
  const [show, setShow] = useState(false);
  const [random, setRandom] = useState(Math.random());
  useEffect(() => {
    if (!localStorage.getItem("previous_user_key")) fetchData();
  }, []);

  const fetchData = () => {
    apis.caseList
      .get()
      .then((res) => setReports(res))
      .catch((err) => console.log(err));
  };
  const makeDelete = () => {
    apis.caseList.delete(id).then(() => {
      fetchData();
      toast.success("Report Removed !!");
      setShow(false);
    });
  };

  const setDelete = (id) => {
    setID(id);
    setShow(true);
  };

  return (
    <>
      {/* <div className="d-flex">
        <SearchForm setReports={setReports} />
        <button
          style={{ maxWidth: 150, marginLeft: "auto" }}
          onClick={() => {
            fetchData();
            deleteLocalData();
          }}
          className="btn otjs-button otjs-button-orange p-2"
        >
          Refresh
        </button>
        <div style={{ maxWidth: 150, marginLeft: 20 }}>
          <ExportExcel key={Math.random()} data={reports} />
        </div>
      </div> */}
      <div>
        <div className="d-flex ms-auto" style={{ width: "max-content" }}>
          <button
            style={{ maxWidth: 150 }}
            onClick={() => {
              fetchData();
              deleteLocalData();
              setRandom(Math.random());
            }}
            className="btn otjs-button otjs-button-orange p-2"
          >
            Refresh
          </button>
          <div style={{ maxWidth: 150, marginLeft: 20 }}>
            <ExportExcel key={Math.random()} data={reports} />
          </div>
        </div>
        <SearchForm key={random} setRandom={setRandom} setReports={setReports} />
      </div>
      <br />
      <DoctorListTable key={random} reports={reports} setDelete={setDelete} />
      <Modal show={show} id="delete" size="sm">
        <Modal.Header closeButton>
          <h5 className="card-title">Unassigned Report</h5>
        </Modal.Header>
        <Modal.Body className="text-center">
          Are You sure to unassigned ?
        </Modal.Body>
        <Modal.Footer>
          <Row className="text-center mt-2">
            <Col>
              <button
                type="button"
                className="otjs-button otjs-button-blue"
                onClick={() => setShow(false)}
              >
                Close
              </button>
            </Col>
            <Col>
              <button
                type="button"
                className="otjs-button otjs-button-red"
                onClick={makeDelete}
              >
                Unassigned
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DoctorCaseList;
